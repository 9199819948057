import { PMCConfig, ProviderMatchProviderV9 } from '@kyruus/types';
import { ReactElement } from 'react';
import { ExtendedProvider, Log, SearchSummary } from 'Src/types';
import ProviderList from './ProviderListV9';

export * from './constants';
export { default } from './ProviderListV9';

// temporary workaround until ProviderListV9 is refactored to TS
interface ProviderListV9Props {
  apptOptions: {
    location_id?: number;
  };
  config: PMCConfig;
  providers: ExtendedProvider[];
  customerCode: string;
  productName: string;
  searchSummary: SearchSummary;
  log: Log;
  profileSummaryParams: {
    from: string;
    location_id: string;
  };
  providerCallbackFunc: (provider: ProviderMatchProviderV9) => void;
  bookingCallbackFunc: (provider: ProviderMatchProviderV9) => void;
}
export const ProviderListV9 = ProviderList as any as (
  props: ProviderListV9Props
) => ReactElement;
